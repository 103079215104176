<template>
  <section>
    <ChangeCase />
  </section>
</template>

<script>
import ChangeCase from '@/components/ChangeCase.vue'

export default {
  name: 'Home',
  components: {
    ChangeCase
  }
}
</script>
