<template>
<section>
    <header>
        <h1>{{ title }}</h1>
    </header>
    <article>
        {{ content }}
    </article>
</section>
</template>
<script>
export default {
  name: 'Card',
  props: {
    title: { String },
    content: { String }
  }
}
</script>
<style lang="stylus" scoped>
section
    border-radius 0.3em
    padding 1em
    /* Add shadows to create the "card" effect */
    box-shadow 0 1px 2px 0 rgba(0,0,0,0.2)
    transition 0.3s
section:hover
    box-shadow 0 2px 4px 0 rgba(0,0,0,0.2)

h1
    text-align start !important

article
    text-align justify

</style>
