<template>
<section>
  <p>Paste or type your text in the area below and choose the type of conversion you want.</p>
  <textarea
    id="textarea"
    aria-label="Paste your the text here and select the transform action."
    placeholder="Paste your the text here and select the transform action."
    rows="10"
    v-model="text"
    autofocus
  ></textarea>
  <div id="toolbar">
      <button aria-label="transform text in lower case" @click="lowerCase">lower case</button>
      <button aria-label="transform text in upper case" @click="upperCase">UPPEP CASE</button>
      <button aria-label="transform text in capitalize sentence" @click="capitalizeSentence">Capitalize sentence</button>
      <button aria-label="transform text in capitalize words" @click="capitalizeWord">Capitalize Word</button>
      <button aria-label="copy text to clipboard" @click="copyClipboard" >Copy to clipboard</button>
      <button aria-label="sanitize text" @click="sanitize" >Sanitize</button>
      <button aria-label="download text" @click="download" >download</button>
      <button aria-label="Share on WhatsApp" @click="shareWhatsApp">
          Share <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.1 3.9C17.9 1.7 15 .5 12 .5 5.8.5.7 5.6.7 11.9c0 2 .5 3.9 1.5 5.6L.6 23.4l6-1.6c1.6.9 3.5 1.3 5.4 1.3 6.3 0 11.4-5.1 11.4-11.4-.1-2.8-1.2-5.7-3.3-7.8zM12 21.4c-1.7 0-3.3-.5-4.8-1.3l-.4-.2-3.5 1 1-3.4L4 17c-1-1.5-1.4-3.2-1.4-5.1 0-5.2 4.2-9.4 9.4-9.4 2.5 0 4.9 1 6.7 2.8 1.8 1.8 2.8 4.2 2.8 6.7-.1 5.2-4.3 9.4-9.5 9.4zm5.1-7.1c-.3-.1-1.7-.9-1.9-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.1-.2.2-.3.2-.6.1s-1.2-.5-2.3-1.4c-.9-.8-1.4-1.7-1.6-2-.2-.3 0-.5.1-.6s.3-.3.4-.5c.2-.1.3-.3.4-.5.1-.2 0-.4 0-.5C10 9 9.3 7.6 9 7c-.1-.4-.4-.3-.5-.3h-.6s-.4.1-.7.3c-.3.3-1 1-1 2.4s1 2.8 1.1 3c.1.2 2 3.1 4.9 4.3.7.3 1.2.5 1.6.6.7.2 1.3.2 1.8.1.6-.1 1.7-.7 1.9-1.3.2-.7.2-1.2.2-1.3-.1-.3-.3-.4-.6-.5z"/></svg>
      </button>
      <button aria-label="Share on Twitter" @click="shareTwitter">
          Share <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"/></svg>
      </button>
      <button aria-label="Share" v-show="isCanShare"  @click="share">
          Share
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
          </svg>
      </button>
      <button aria-label="clear text" @click="clear" class="button-danger">Clear</button>
  </div>
  <div id="info">
      <p>Text size <b>{{textSize}}</b>, Number words {{wordSize}}, Number lines <b>{{lineSize}}</b>.</p>
  </div>
  <Card title="lower case" content="How to convert text from uppercase to lowercase, just paste the text into the text area above and click the lowercase button." />
  <Card title="UPPEP CASE" content="How to convert a text from lowercase to uppercase, just paste the text into the text area above and click the uppercase button." />
  <Card title="Capitalize sentence" content="The capitalize sentence button capitalizes the first letter of all sentences in the text. To do this paste the text in the text area above and click on the capitalize sentence button." />
  <Card title="Capitalize Words" content="The button capitalize words capitalizes the first letter of all words in the text. To do this paste the text in the text area above and click on the button capitalize words." />
  <Card title="Copy to clipboard" content="Copies the transformed text to the clipboard" />
  <Card title="sanitize" content="Removes all special characters. allows only [A - Z] [0 - 9]." />
</section>
</template>

<script>
import Card from '@/components/Card'
import usf from 'useful-simple-functions'
import { useToast } from 'vue-toastification'

const sanitize = s => usf.isValidString(s) ? s.toString().normalize('NFD').replace(/[^A-Z a-z 0-9 \r?\n \r]/g, '') : ''

const toast = useToast()

export default {
  name: 'ChangeCase',
  components: {
    Card
  },
  data () {
    return {
      text: null
    }
  },
  computed: {
    textSize: function () {
      return this.validInput ? this.text.length : 0
    },
    lineSize: function () {
      return usf.lineNumbers(this.text)
    },
    wordSize: function () {
      return usf.wordNumbers(this.text)
    },
    validInput: function () {
      return usf.isNotNull(this.text)
    },
    isCanShare: function () {
      return navigator.share !== undefined
    }
  },
  methods: {
    upperCase () {
      if (this.validInput) {
        this.text = usf.upperCase(this.text)
      } else {
        this.notValidInputMessage()
      }
    },
    lowerCase () {
      if (this.validInput) {
        this.text = usf.lowerCase(this.text)
      } else {
        this.notValidInputMessage()
      }
    },
    capitalizeSentence () {
      if (this.validInput) {
        this.lowerCase()
        this.text = usf.capitalizeSentence(this.text)
      } else {
        this.notValidInputMessage()
      }
    },
    capitalizeWord () {
      if (this.validInput) {
        this.lowerCase()
        this.text = usf.capitalizeWord(this.text)
      } else {
        this.notValidInputMessage()
      }
    },
    copyClipboard () {
      if (this.validInput) {
        navigator.clipboard.writeText(this.text).then(
          function () {
            toast.success('Copy successfully!')
          },
          function () {
            toast.error('Error copy clipboard!')
          }
        )
      } else {
        this.notValidInputMessage()
      }
    },
    sanitize () {
      if (this.validInput) {
        this.text = sanitize(this.text)
      } else {
        this.notValidInputMessage()
      }
    },
    download () {
      if (this.validInput) {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([this.text], { type: 'text/plain' }))
        link.setAttribute('download', 'changecase.txt')
        link.click()
        window.URL.revokeObjectURL(link.href)
      } else {
        this.notValidInputMessage()
      }
    },
    shareWhatsApp () {
      if (this.validInput) {
        window.open(`whatsapp://send?text=${this.text}`)
      } else {
        this.notValidInputMessage()
      }
    },
    shareTwitter () {
      if (this.validInput) {
        window.open(`https://twitter.com/intent/tweet/?text=${this.text}`)
      } else {
        this.notValidInputMessage()
      }
    },
    async share () {
      if (this.validInput) {
        const shareData = {
          text: this.text
        }
        try {
          await navigator.share(shareData)
          toast.success('Shared successfully')
        } catch (err) {
          toast.warning('Error: ' + err)
        }
      } else {
        this.notValidInputMessage()
      }
    },
    notValidInputMessage () {
      toast.warning('Type or paste any text here in the textarea!')
    },
    clear () {
      this.text = null
      if (!this.text) toast.success('Clear for success!')
    }
  }
}
</script>

<style lang="stylus" scoped>
section
    display flex
    flex-direction column
    align-items center
    justify-content center
    padding 1em
    gap: 0.7em

textarea
    width 100%
    padding 0.5em

#toolbar
    display inline-flex
    flex-wrap wrap
    gap: 0.5em

#info
    display inline-flex
    flex-wrap wrap
    gap 1em
p
    padding 0
    margin 0

button
  padding 0.5em 1em
  text-align center
  text-decoration none
  display inline-block
  font-size 1em
  margin 0.25em 0.125em
  transition-duration 0.4s
  cursor pointer
  background-color white
  color black
  border 0.125em solid black
  border-radius 0.125em

button:hover
button:focus
  background-color black
  color white
  fill white

.button-danger
  color black
  border 2px solid #f44336

.button-danger:hover
.button-danger:focus
  background-color #f44336
  color white
svg
  width 1em
  height 1em

</style>
